<template>
  <div>
    <h4 class="page-title">{{ $t("LEADERS.OUR_LEADERS") }}</h4>
    <div class="d-flex align-items-center justify-content-end mb-3">
      <Button customClass="leader-btn" v-if="canLeader" @click="handleOpenLeaderModal">
        {{ $t("LEADERS.BECOME_LEADER") }}
      </Button>
    </div>

    <div class="main-card" v-if="topLeaders.length">
      <div class="text-center p-3" v-if="topLeadersLoading">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>

      <div v-else>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h6 class="top-leaders">{{ $t("LEADERS.TOP_LEADERS") }}</h6>
        </div>

        <div class="d-flex leaders-container">
          <leader-card
            v-for="(leader, index) in topLeaders"
            :key="index"
            :leader="leader"
            :loading="followLoading"
            @handleSelectedLeader="handleSelectedLeader"
            @handleUnfollow="handleFollowOrUnfollow"
            @handleRedirectLeaderProfile="handleRedirectLeaderProfile"
          />
        </div>
      </div>
    </div>
    <div class="main-card">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h6 class="top-leaders">{{ $t("LEADERS.OUR_LEADERS") }}</h6>
        <TextField
          type="search"
          name="search"
          value=""
          @input="handleSearch($event)"
          :placeholder="$t('GLOBAL_SEARCH')"
        />
      </div>
      <div class="text-center p-3" v-if="leadersLoading">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <div v-else>
        <div v-if="leaders.length">
          <b-table
            a-tag="leaderTable"
            class="custom-table"
            responsive
            :items="leaders"
            :fields="fields"
            :busy="loadingTable"
            @sort-changed="handleSortBy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <div
                class="d-flex align-item-center name-container pointer"
                @click="handleRedirectLeaderProfile(data.item.id)"
              >
                <image-circle :defaultImage="data.item.image" :showCoin="true" />
                <div class="ml-2">
                  <h4>
                    {{ data.item.name }}
                  </h4>
                  <span>
                    {{ $t("LEADERS.ACTIVATED") }}
                    {{ data.item.last_active }}
                  </span>
                </div>
              </div>
            </template>
            <!-- <template #cell(exchange)="data">
              <img class="exhange-logo" :src="data.item.exchange_logo" alt="" />
            </template> -->
            <template #cell(followers)="data">
              <h4 class="follower-title">
                {{ data.item.followers_count }}
                <span>
                  {{ $t("LEADERS.FOLLOWERS") }}
                </span>
              </h4>
            </template>
            <template #cell(daily_change)="data">
              <h4
                class="change-title"
                :class="{
                  'text-danger': handleStringStartsWith(data.item.daily_change),
                }"
              >
                {{ data.item.daily_change }}
              </h4>
            </template>
            <template #cell(weekly_change)="data">
              <h4
                class="change-title"
                :class="{
                  'text-danger': handleStringStartsWith(data.item.weekly_change),
                }"
              >
                {{ data.item.weekly_change }}
              </h4>
            </template>
            <template #cell(monthly_change)="data">
              <h4
                class="change-title"
                :class="{
                  'text-danger': handleStringStartsWith(data.item.monthly_change),
                }"
              >
                {{ data.item.monthly_change }}
              </h4>
            </template>
            <template #cell(performance)="data">
              <div class="chart-container">
                <line-chart
                  v-if="data.item.wallet"
                  :chartdata="handleSetChartData(data.item.wallet)"
                  :options="options"
                  class="chart"
                />
              </div>
            </template>
            <template #cell(actions)="data">
              <Button
                v-if="user && !user.is_leader && user.exchanges.data.length"
                color="outline"
                customClass=" btn-follow"
                @click="handleFollowOrUnfollow(data.item)"
                a-tag="buttonUnfollow"
              >
                {{ data.item.followed_at ? $t("LEADERS.UNFOLLOW") : $t("LEADERS.FOLLOW") }}
              </Button>
              <Button
                v-else-if="user && !user.is_leader && !user.exchanges.data.length"
                color="outline"
                customClass="btn-primary"
                :title="$t('LEADERS.FOLLOW_LINK_EXCHANGE')"
                v-b-tooltip.hover="{ customClass: 'tooltip-primary' }"
                disabled
                a-tag="buttonFollow"
              >
                {{ $t("LEADERS.FOLLOW") }}
              </Button>
            </template>
          </b-table>
        </div>
        <div class="text-center" v-else>
          <h6 class="py-3">
            {{ $t("GLOBAL_NO_MESSAGE") }}
          </h6>
        </div>

        <pagination
          :current-page="page"
          :per-page="perPage"
          :total-items="totalItems"
          @onPageChange="changePage"
        />
      </div>
    </div>

    <!-- become leader modal -->
    <leader-modal :loading="becomeLeaderLoading" @handleAddLeader="handleAddLeader" />

    <!-- follow leader -->
    <leader-follow-modal
      :loading="followLoading"
      :leader="selectedLeader"
      @handleFollow="handleFollow"
      @closeModal="handleCloseFollowModal"
    />
    <!-- confirmation modal -->
    <confirmation-modal
      name="confirmation-modal"
      :title="confirmation.title"
      :message="confirmation.message"
      :subMessage="confirmation.subMessage"
      :confirmTitle="confirmation.confirmTitle"
      :showDangerImage="confirmation.showDangerImage"
      :isConfirmationLoading="isConfirmationLoading"
      @onConfirm="onConfirm"
      @onCancel="resetConfirmationModal"
    />
  </div>
</template>

<script>
import LeaderCard from "@/components/Application/Leaders/LeaderCard";
import ImageCircle from "@/components/Shared/ImageCircle";
import LeaderModal from "@/components/Application/Leaders/LeaderModal";
import LeaderFollowModal from "@/components/Application/Leaders/LeaderFollowModal";
import LineChart from "@/components/Application/Chart/LineChart";
import Pagination from "@/components/Shared/Pagination";
import debounce from "lodash/debounce";

import {
  getLeadersRequest,
  postFollowRequest,
  postUnfollowRequest,
  postAddLeaderRequest,
} from "@/api/leaders";
import { getDateFormat, handleStringStartsWith } from "@/helpers";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LeaderCard,
    ImageCircle,
    LeaderModal,
    LeaderFollowModal,
    LineChart,
    Pagination,
  },
  data() {
    return {
      handleStringStartsWith,
      topLeadersLoading: false,
      leadersLoading: false,
      topLeaders: [],
      leaders: [],
      followLoading: false,
      becomeLeaderLoading: false,
      fields: [
        { key: "name", sortable: true, label: this.$t("LEADERS.LEADER") },
        {
          key: "followers",
          sortable: false,
          label: this.$t("LEADERS.FOLLOWERS"),
        },
        { key: "daily_change", sortable: true, label: this.$t("LEADERS.DAY") },
        {
          key: "weekly_change",
          sortable: true,
          label: this.$t("LEADERS.WEEK"),
        },
        {
          key: "monthly_change",
          sortable: true,
          label: this.$t("LEADERS.MONTH"),
        },
        {
          key: "performance",
          sortable: false,
          label: this.$t("LEADERS.PERFORMANCE"),
        },
        { key: "actions", label: "" },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,

        tooltips: {
          enabled: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
      selectedLeader: null,
      page: 1,
      perPage: 10,
      totalItems: 0,
      confirmation: {
        title: "",
        message: "",
        subMessage: "",
        confirmTitle: "",
        showDangerImage: true,
      },
      isConfirmationLoading: false,
      loadingTable: false,
      params: {},
    };
  },
  created() {
    this.handleLoadTopLeaders();
    this.handleLoadLeaders();
  },
  computed: {
    ...mapGetters(["user", "token"]),
    canLeader() {
      if (
        this.user &&
        !this.user.is_leader &&
        this.user.exchanges &&
        this.user.exchanges.data.length &&
        this.user.followedLeaders &&
        !this.user.followedLeaders.data.length
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions(["SetUser", "ShowToast"]),

    handleLoadTopLeaders() {
      const params = {
        search: "is_top:1",
      };
      this.topLeadersLoading = true;
      this.ApiService(getLeadersRequest(params))
        .then(({ data }) => {
          this.topLeaders = data.data;
        })
        .finally(() => {
          this.topLeadersLoading = false;
        });
    },
    handleLoadLeaders(params = null, loading = true) {
      if (loading) {
        this.leadersLoading = true;
      }
      this.loadingTable = true;
      this.ApiService(getLeadersRequest(params))
        .then(({ data }) => {
          this.leaders = data.data;
          this.totalItems = data.meta.pagination.total;
          this.perPage = data.meta.pagination.per_page;
        })
        .finally(() => {
          this.leadersLoading = false;
          this.loadingTable = false;
        });
    },

    // follow

    handleFollowOrUnfollow(leader) {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      if (leader.followed_at) {
        this.selectedLeader = leader;
        this.confirmation = {
          title: this.$t("LEADERS.UNFOLLOW"),
          message: this.$t("LEADERS.ARE_YOU_UNFOLLOW"),
          confirmTitle: this.$t("GLOBAL_YES"),
          subMessage: leader.name,
          showDangerImage: true,
        };
        this.$bvModal.show("confirmation-modal");
      } else {
        this.handleSelectedLeader(leader);
      }
    },

    handleSelectedLeader(leader) {
      this.selectedLeader = leader;
      this.$bvModal.show("leader-follow-modal");
    },

    handleFollow(data) {
      const body = {
        id: this.selectedLeader.id,
        stop_following_value: data.followingValue,
        convert_assets_to: data.converAssetsTo,
      };
      this.followLoading = true;
      this.ApiService(postFollowRequest(body))
        .then(({ data }) => {
          this.handleCloseFollowModal();
          this.handleLoadTopLeaders();
          const params = {
            page: this.page,
          };
          this.handleLoadLeaders(params);
          const param = {
            token: this.token,
          };
          this.SetUser(param);
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.followLoading = false;
        });
    },

    onConfirm() {
      const body = {
        id: this.selectedLeader.id,
      };
      this.isConfirmationLoading = true;
      this.ApiService(postUnfollowRequest(body))
        .then(({ data }) => {
          this.handleLoadTopLeaders();
          const params = {
            page: this.page,
          };
          this.handleLoadLeaders(params);
          const param = {
            token: this.token,
          };
          this.SetUser(param);
          this.resetConfirmationModal();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.isConfirmationLoading = false;
        });
    },
    resetConfirmationModal() {
      this.$bvModal.hide("confirmation-modal");
      this.confirmation = {
        title: "",
        message: "",
        confirmTitle: "",
        subMessage: "",
        showDangerImage: true,
      };
      this.isConfirmationLoading = false;
      this.selectedLeader = null;
    },
    handleRedirectLeaderProfile(id) {
      this.$router.push(`/leaders/${id}`);
    },

    handleCloseFollowModal() {
      this.$bvModal.hide("leader-follow-modal");
      this.selectedLeader = null;
    },

    handleSetChartData(wallet) {
      let chartLabels = [];
      let chartValue = [];
      if (wallet.data) {
        const walletFormat = JSON.parse(wallet.data);
        chartLabels = [...walletFormat.snapshotVos]?.map((chart) =>
          getDateFormat(new Date(chart.updateTime), "DD/MM")
        );
        chartValue = [...walletFormat.snapshotVos]?.map((chart) => chart.data.totalAssetOfBtc);
      }

      return {
        labels: chartLabels,
        datasets: [
          {
            data: chartValue,
            backgroundColor: "transparent",
            borderColor: "#532680",
            pointBackgroundColor: "#532680",
          },
        ],
      };
    },
    handleSearch: debounce(function (search) {
      const params = {
        page: 1,
        search: `user.name:${search}`,
      };
      Object.assign(this.params, params);
      this.handleLoadLeaders(this.params, false);
    }, 500),

    handleSortBy(sort) {
      const params = {
        sortedBy: sort.sortDesc ? "desc" : "asc",
        orderBy: sort.sortBy,
      };

      Object.assign(this.params, params);

      this.handleLoadLeaders(this.params, false);
    },

    changePage(page) {
      this.page = page;
      const params = {
        page,
        per_page: 1,
      };
      Object.assign(this.params, params);

      this.handleLoadLeaders(this.params, false);
    },
    handleOpenLeaderModal() {
      this.$bvModal.show("become-leader-modal");
    },
    handleAddLeader(data) {
      const socialMedia = data.socialMedia.reduce((obj, cur) => {
        return { ...obj, [cur.title]: cur.value };
      }, {});
      const body = {
        following_fee: data.followingFee,
        bio: data.bio,
        pay_type: data.payType || undefined,
        pay_value: data.payValue || undefined,
        tips_address: data.tipsAddress,
        tips_network: data.tipsNetwork,
        socials: socialMedia,
      };

      this.becomeLeaderLoading = true;
      this.ApiService(postAddLeaderRequest(body))
        .then(({ data }) => {
          this.handleLoadTopLeaders();
          this.handleLoadLeaders();
          const params = {
            token: this.token,
          };
          this.SetUser(params);
          this.$bvModal.hide("become-leader-modal");
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.becomeLeaderLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
