<template>
  <b-modal id="become-leader-modal" size="lg" hide-footer @hide="closeModal">
    <template v-slot:modal-header="{ close }">
      <h6 class="mb-0">
        {{ $t("LEADERS.BECOME_LEADER") }}
      </h6>
      <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
    </template>

    <div class="modal-body" :class="$i18n.locale == 'en' ? '' : 'rtl'">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formAddLeader">
          <div class="">
            <b-form-group>
              <TextAreaField
                v-model="form.bio"
                rows="4"
                rules="required"
                no-resize
                :label="$t('LEADERS.DESCRIPTION')"
                :name="$t('LEADERS.DESCRIPTION')"
              />
            </b-form-group>

            <!-- address -->
            <b-form-group>
              <div class="tip">
                <h5 class="title">{{ $t("LEADERS.TIP_ADDRESS") }}</h5>
                <b-form-group>
                  <b-input-group class="align-items-start flex-nowrap">
                    <template #prepend>
                      <SelectField
                        v-model="form.tipsNetwork"
                        :name="$t('LEADERS.TIP_ADDRESS')"
                        :options="networks"
                        text-field="value"
                        value-field="value"
                      />
                    </template>

                    <TextField
                      class="w-100"
                      rules="url"
                      v-model="form.tipsAddress"
                      :name="$t('LEADERS.SOCIAL')"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </b-form-group>
            <!-- social media -->
            <b-form-group>
              <div class="social-media">
                <h5 class="title">{{ $t("LEADERS.SOCIAL_MEDIA") }}</h5>
                <b-form-group v-for="(social, index) in form.socialMedia" :key="index">
                  <b-input-group class="align-items-start flex-nowrap">
                    <template #prepend>
                      <SelectField
                        v-model="social.title"
                        :value="index"
                        :name="`social-${index}`"
                        :options="socials"
                        text-field="value"
                        value-field="value"
                      />
                    </template>

                    <TextField
                      class="w-100"
                      v-model="social.value"
                      rules="required|url"
                      :name="social.title"
                    />
                    <button
                      type="button"
                      class="btn btn-text btn-delete ml-2"
                      @click="handleRemoveSocial(index)"
                    >
                      <img src="@/assets/images/delete.svg" alt="" />
                    </button>
                  </b-input-group>
                </b-form-group>

                <button type="button" class="btn btn-text btn-add" @click="addNewSocial">
                  <b-icon class="icon" icon="plus"></b-icon>
                  {{
                    form.socialMedia.length
                      ? $t("LEADERS.ADD_ANOTHER_ACCOUNT")
                      : $t("LEADERS.ADD_ACCOUNT")
                  }}
                </button>
              </div>
            </b-form-group>

            <b-form-group>
              <h5 class="title">{{ $t("LEADERS.FOLLOWING_FEE") }}</h5>
              <div class="d-flex">
                <RadioButton
                  v-model="form.followingFee"
                  :options="feesOptions"
                  :name="$t('LEADERS.FOLLOWING_FEE')"
                  value-field="item"
                  text-field="name"
                  rules="required"
                  stacked
                />
                <div class="ml-3 paid-container w-100" v-if="form.followingFee === 'paid'">
                  <b-form-group>
                    <RadioButton
                      v-model="form.payType"
                      :options="paidOptions"
                      :name="$t('LEADERS.FOLLOWING_FEE')"
                      value-field="item"
                      text-field="name"
                      rules="required"
                      stacked
                    />
                  </b-form-group>
                  <b-form-group>
                    <TextField
                      v-if="form.payType === 'fixed' || form.payType === 'percentage'"
                      v-model="form.payValue"
                      rules="required|numeric|max_value:20"
                      :placeholder="
                        form.payType === 'fixed'
                          ? `${$t('LEADERS.MAX_VALUE')}$`
                          : `${$t('LEADERS.MAX_VALUE')}%`
                      "
                      :name="$t('LEADERS.VALUE')"
                      type="number"
                      min="0"
                    />
                  </b-form-group>
                </div>
              </div>
            </b-form-group>
          </div>
          <b-form-group class="d-flex justify-content-end">
            <div class="d-inline-block mr-3">
              <Button
                type="button"
                color="light-dark"
                customClass="text-uppercase  font-14"
                @click="$bvModal.hide('become-leader-modal')"
              >
                {{ $t("GLOBAL_CANCEL") }}
              </Button>
            </div>
            <Button
              type="submit"
              color="primary"
              :loading="loading"
              customClass="text-uppercase font-14"
            >
              {{ $t("GLOBAL_ADD") }}
            </Button>
          </b-form-group>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>
<script>
import { getLoadSettingsByTypeRequest } from "@/api/leaders";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        followingFee: null,
        bio: null,
        payType: null,
        payValue: null,
        tipsAddress: null,
        tipsNetwork: "ethereum",
        socialMedia: [],
      },

      socials: [],
      networks: [],

      feesOptions: [
        { item: "free", name: this.$t("LEADERS.FREE") },
        { item: "paid", name: this.$t("LEADERS.PAID") },
      ],
      paidOptions: [
        { item: "percentage", name: this.$t("LEADERS.UNDER_MANAGMENT") },
        { item: "fixed", name: this.$t("LEADERS.PER_MONTH") },
      ],
    };
  },
  created() {
    this.handleLoadSettgins("network");
    this.handleLoadSettgins("social");
  },
  methods: {
    handleLoadSettgins(type) {
      const params = {
        type,
      };

      this.ApiService(getLoadSettingsByTypeRequest(params)).then(({ data }) => {
        if (type === "social") {
          this.socials = data.data;
        } else {
          this.networks = data.data;
        }
      });
    },

    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form = {
        followingFee: null,
        bio: null,
        payType: null,
        payValue: null,
        tipsAddress: null,
        tipsNetwork: "ethereum",
        socialMedia: [],
      };
    },
    onSubmit() {
      this.$emit("handleAddLeader", this.form);
    },
    handleRemoveSocial(index) {
      this.form.socialMedia.splice(index, 1);
    },
    addNewSocial() {
      this.form.socialMedia.push({
        title: "",
        value: "",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
